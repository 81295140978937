import React from "react";
//import React, { useEffect, useState } from "react";
import { Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
// import logoutimg  from '../../Usefull/images/logout.svg'
export default function Logout() {

  //const history = useHistory();
  // function logoutfun() {
  //   const toLogout = confirm("Are you sure to logout ?");
  //     if (toLogout) {
  //     localStorage.clear();
  //     //history.push("/login");
  //    return <Navigate to="/account/login"  />
  //     //window.location.href = "/";  
  //   }
  //   else
  //   {
  //     return <Navigate to="/app/dashboard"  />
  //   }
   
  // }
  const logoutfun = () => {
    /* eslint-disable */
    const toLogout = confirm("Are you sure to logout ?");
    
    /* eslint-enable */
    if (toLogout) {
      localStorage.clear();
      
      //history.push("/login");
     // navigate('/account/login');
      //<Navigate to="/account/login"  />
      window.location.href = "/";  
    }
  };


  return (
            
    <>
   
   <Link to="/"  onClick={() => logoutfun()} className="logout">
     {/* <img src={logoutimg} alt="Logout" ></img> */}
     <FontAwesomeIcon icon={faPowerOff} />
     <span>Logout</span></Link>
             
           
    </>
  );
};

