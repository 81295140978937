import React ,{ useState}from 'react';
import {Link
} from "react-router-dom";
import { useForm } from "react-hook-form";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft,faCamera } from '@fortawesome/free-solid-svg-icons'

import * as Constant from '../../Utilities/Constant';
import { callApi,APIcustomerID } from '../../Utilities/Api'
import Footer from '../Shared/Footer';

const Upload = () => 
{
  const { register, handleSubmit, formState: { errors }, watch ,setValue} = useForm();
  const [message, setMessage] = useState();
  // const history = useHistory();
  const [isvaliddata, setData] = React.useState(false);

  const [toggle, setToggle] = useState(false)

  //const state = {value:''}
 
  
  
  

  const onSubmit = (data, e) => {

    if(isvaliddata)
{
    
    var FileExt="."+getFileExtension(data.podupload[0].name);
    setMessage({data: "Your POD Upload is in progress...",type: "alert alert-warning",});
        const requestData={PODID:0,GRNum:data.awbno,PODNumber:data.awbno
        ,Attachment:FileExt
        ,Remarks:''
        ,DeliveryDate:Date.now
        ,DeliveryTime:Date.now
        ,RecieverName:''
        ,RecieverPhone:''
        ,AdminComment:''
        ,StatusId:1
        ,StatusName:''
        ,UserId:APIcustomerID()
        ,DMLFlag:'P'
        };//JSON.stringify(data)
        const usaData=callApi("POST",requestData,Constant.awbpodupload);
        usaData.then((responseData)=>{
          var poID=responseData.DataResponse;
          const Fordata = new FormData();
          Fordata.append("files", data.podupload[0]);
          Fordata.append("grno", data.awbno);
          Fordata.append("podno", poID);

          const requestOptions = {
            method:"POST",
            //headers: { 'Content-Type': 'application/json' },
            body: Fordata
        };
        const response =  fetch(Constant.awbpoduploadfile, requestOptions);
        //const usaData = response.json();
        
          //console.log(usaData);


          setMessage({
            data: responseData.DataResponse.error || "Your POD Upload has been updated successfully...",
            type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
          });
          !responseData.DataResponse.error && e.target.reset();
    
        });
      }
      else
      {

      }
  };


function getFileExtension(filename){
    // get file extension
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
    return extension;
}
  const OnchangeGrno =(data, e) => {
   if(data.target.value.length>5)
   {
    var GRNOData=data.target.value;
    getCharacters(GRNOData);
   }
  };
  function getCharacters(GRNOData) {
    const requestData={GRNo:GRNOData}
    const usaProfileDetails=callApi("POST",requestData,Constant.Awbawbpodvalidate);
    usaProfileDetails.then((responseData)=>{
      if(responseData.DataResponse=='Invalid')
      {
        setData(false);
        setMessage({
          data: GRNOData + " is a invalid consignment No! Please try again!",
          type: "alert alert-danger" ,
        });
      }
     else if(responseData.DataResponse=='Exist')
      {
        setData(false);
        setMessage({
          data: "POD already Uploaded for the consignment No " + GRNOData,
          type: "alert alert-danger" ,
        });
      }
      else if(responseData.DataResponse=='Valid')
      {
        setData(true);
        // setMessage({
        //   data: GRNOData + " Successfully Uploaded!",
        //   type: "alert alert-success" ,
        // });
      }
      else{
        setData(false);
      setMessage({
        data: "Failed to update " + GRNOData + " Please try again",
        type: "alert alert-danger" ,
      });
    }

        //setItems(responseData.DataResponse.map(({ StatusName,StatusId }) => ({ label: StatusName, value: StatusId })));

        });
  }

        return(
            <div className="App">

            
<header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <Link to="/app/dashboard"  className="back">
        
        <FontAwesomeIcon icon={faArrowLeft} />
</Link>
                    <h1>
                    POD Upload
                    </h1>

                </div>
            </div>
        </div>
    </header>
    <section>
        <div className="container">
            <div className="row justify-content-center mt-3">
            <div className="col-md-8  mt-3">
            {message && (
          <div
            role="alert"
            className={message.type}>
            {message.data}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
<div className="upload_inv">
<div className="form-group Scanners">
{toggle && (
       <BarcodeScannerComponent 
       // width={400}
       // height={400}
       // stopStream={this.state.isstopStream}
       onUpdate={(err, result) => {
         if (result) {
          setValue('awbno', result.text);
          getCharacters(result.text);
           setToggle(!toggle);
         }

        //  else setData("");
       }}
     />
      )}
    
   
   <div className="input-group mb-3">
   <input type="text" 

   { ...register('awbno', { required:{value:true,message:"Enter AWB No"},
   
  })
     } 
     
     onChange={(e) => {
      
       OnchangeGrno(e);
  }}
   required="required" placeholder="Enter AWB No" className="form-control form_control" />
  {errors.awbno && (
                <span className=" mandatory">
                  {errors.awbno.message}
                </span>
              )}
  <div className="input-group-append">
    <button className="btn btn-outline-secondary btncam " type="button"
    onClick={() => setToggle(!toggle)}
  
     ><FontAwesomeIcon icon={faCamera} /></button>
  </div>
</div>
    
    

    
        
    </div>
    
    
    
    <div className="form-group">
    <input type="file" { ...register('podupload', { required:{value:true,message:"POD Upload"}
              
            })
               } className="form-control form_control" placeholder="POD Upload" />
       
       {errors.podupload && (
               <span className=" mandatory">
                 {errors.podupload.message}
               </span>
             )}
       
    </div>
    <button type="submit" className="inv_smt">
        Submit
    </button>
</div>
</form>
</div>
                
            </div>
        </div>
    </section>
<Footer />
            </div>

            
);
};

export default Upload;