

import React, { Component }from "react";
import {withRouter,Link
} from "react-router-dom";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft,faCamera } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi,APIcustomerID } from '../../Utilities/Api'
import Footer from '../Shared/Footer';
class Track extends Component {
  
  constructor(props) 
  {
   
    
    super(props);
  
    this.state = {
      GRNo:'',
      GRLogs:[],
      GRDetails:[],
      GRPOD: [],
      isSubmitting:false,
      isstopStream:false,
      
    };
    this.handleChangeMenu = this.handleChangeMenu.bind(this);
    this.handleChangegr = this.handleChangegr.bind(this);
  
    this.handleSubmit = this.handleSubmit.bind(this);
    //this.handlebuttonscanner = this.handlebuttonscanner.bind(this);
    // this.toolboxview = this.toolboxview.bind(this);
  }
  handleChangeMenu() {
    this.setState(prevState => ({
      isstopStream: !prevState.isstopStream
      }));
      console.log(this.state.isstopStream)
      
  }
  handleChangegr(event) {
    this.setState({GRNo: event.target.value.toLowerCase()});
  }
  // handlebuttonscanner() {
  //   if(this.state.isstopStream==true)
  //   {
  //     this.setState({isstopStream: false});
  //   }
  //   else
  //   {
  //     this.setState({isstopStream: true});
  //   }
    
  //   console.log(this.state.isstopStream);
  // }   
  
 
 
  GrDetails=()=>{
  
       
        
         
    }

     
   
  handleSubmit(event) 
  {
   this.setState({isSubmitting:true}); 
    const requestData={GRNo:this.state.GRNo}
        const SrGrDetails=callApi("POST",requestData,Constant.AwbTrancking);
        SrGrDetails.then((responseData)=>{
            
        this.setState({GRLogs:responseData.DataResponse.GRLogs,GRDetails:responseData.DataResponse.GRDetails,GRPOD:responseData.DataResponse.GRPOD});
        
            });
  
     event.preventDefault();
     this.setState({isSubmitting:false});
  
   
  }
 
  render() 
  {
  return (
    
    <>
       <div className="App">

            
<header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <Link to="/app/dashboard"  className="back">
        
        <FontAwesomeIcon icon={faArrowLeft} />
</Link>
                    <h1>
                    AWB Track
                    </h1>

                </div>
            </div>
        </div>
    </header>
    <section>
        <div className="container">
            <div className="row justify-content-center mt-3">
            <div className="col-md-8  mt-3">
            
<div className="upload_inv">
<form onSubmit={this.handleSubmit}  autoComplete="off">
    <div className="form-group Scanners">
    {this.state.isstopStream ? (
                          <BarcodeScannerComponent 
                          // width={400}
                          // height={400}
                          // stopStream={this.state.isstopStream}
                          onUpdate={(err, result) => {
                            if (result){ this.setState({GRNo:result.text});
                            this.setState(prevState => ({
                              isstopStream: !prevState.isstopStream
                              }));
                          }
                            else ;
                          }}
                        />
                        ) : (
                          ""
                        )}
   
   <div className="input-group mb-3">
   <input type="text" value={this.state.GRNo} required="required" placeholder="Enter AWB No" className="form-control form_control" onChange={this.handleChangegr} />
  
  <div className="input-group-append">
    <button className="btn btn-outline-secondary btncam " type="button" onClick={this.handleChangeMenu}><FontAwesomeIcon icon={faCamera} /></button>
  </div>
</div>
    
    

    
        
    </div>
    

    <button type="submit" className="inv_smt">
    {this.state.isSubmitting ? (
                          "Loading..."
                        ) : (
                          "TRACK NOW"
                        )}
        
    </button>
    </form>
</div>
            </div>
                
            </div>
            <div className="row invoice_list mt-4">
            {(this.state.GRLogs.length > 0) ? (
                         <div className="col-md-8 offset-md-2">
                         <p><b>Tracking History</b></p>
                         <div className="box">
                            
                              
                                 <div className="cont">
                                   
                                 {(this.state.GRLogs.length > 0) ? this.state.GRLogs.map((item, index) => {
                       
                       return  (
                         <p key={item.LogID}>
                         <span>{Moment(item.Sysdate).format("DD-MMM-yyyy")}</span>   <b>{item.LogMsg}</b>
                       </p>
                       
                       
                       )
                       
                               }):""}
                                 </div>
                             
                         </div>
                     </div> 
                        ) : (
                          ""
                        )}
          

            
                {(this.state.GRDetails.length > 0) ? (
                         <div className="col-md-8 offset-md-2  mt-3">
                         <p><b>Shipment Details</b></p>
                         <div className="box">
                           {(this.state.GRDetails.length > 0) ? this.state.GRDetails.map((item, index) => {
                       
                       return  (
                         <div className="cont" key={item.Id}>
                          
                            <p >
                                         AWB No. <strong>{item.GR_Num}</strong>
                                     </p>
                                     <p>
                                         Pcs <strong>{item.No_of_Pkts}</strong>
                                     </p>
                                     <p>
                                         Origin <strong>{item.Origin}</strong>
                                     </p>
                                     <p>
                                         Destination <strong>{item.Destination}</strong>
                                     </p>
                                     <p>
                                         Forwarding No <strong>{item.FWDNumber}</strong>
                                     </p>
                                     <p>
                                         Consignor <strong>{item.Consignor}</strong>
                                     </p><p>
                                         Consignee <strong>{item.Consignee}</strong>
                                     </p>
                                     <p></p>
                          </div>
                         
                       
                       
                       )
                       
                               }):""}
                                    
                            
                         </div>
                     </div>
                        ) : (
                          ""
                        )}
                
                
                {(this.state.GRPOD.length > 0) ? (
                           <div className="col-md-8 offset-md-2 mt-3">
                           <p><b>Delivery Details</b></p>
                           <div className="box">
                           {(this.state.GRPOD.length > 0) ? this.state.GRPOD.map((item, index) => {
                         
                         return  (
                           <div className="cont" key={item.Id}>
                            
                                       <p>
                                           AWB No. <strong>{item.GRNum}</strong>
                                       </p>
                                       <p>Booking Date <strong>{Moment(item.Sysdate).format("DD-MMM-yyyy")}</strong>
                                         </p>
                                       <p>
                                           Receiver Name <strong>{item.RecieverName}</strong>
                                       </p>
                                       <p>
                                           Delivery Date <strong>{Moment(item.DeliveryDate).format("DD-MMM-yyyy")} </strong>
                                       </p>
                            </div>
                           
                         
                         
                         )
                         
                                 }):""}
                              
                           </div>
                       </div>
                        ) : (
                          ""
                        )}
               

            </div>
        </div>
    </section>
<Footer />
            </div>
      {/* <BarcodeScannerComponent
        width={200}
        height={200}
        torch={torchOn}
        
        onUpdate={(err, result) => {
          if (result) setData(result.text);
          else setData("Not Found");
        }}
      />
      <p>{data}</p>
      <button onClick={() => setTorchOn(!torchOn)}>
        Switch Torch {torchOn ? "Off" : "On"}
      </button> */}
    </>
  );
}
}

export default withRouter(Track);

