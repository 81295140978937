import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,Redirect
} from "react-router-dom";


 import Login from "./Components/Login/Login";
//  import Register from "./Components/Register/Register";
 import Home from "./Components/Home/Home";
 import Profile from "./Components/Profile/Profile";
 import ChangePassword from "./Components/Profile/ChangePassword";
 import AWBTrack from "./Components/Awb/Track";
 import DeliveryUpdate from "./Components/Delivery/update";
 import PODUpload from "./Components/Pod/Upload";
 
 
 
const authGuard = (Component) => () => 
{
  return localStorage.getItem("token") ? (
    <Component />
  ) : (
    <Redirect to="/account/login" />
  );
};
const Routes = (props) => (
  
  <Router {...props}>
    <Switch>
    <Route exact path="/account/login">
        <Login />
     </Route>
      <Route exact path="/" >
      {authGuard(Home)}
     </Route>
     <Route exact path="/app/dashboard" >
     {authGuard(Home)}
     </Route> 
       <Route exact path="/app/profile"  >
         {authGuard(Profile)}
       </Route>
       <Route exact path="/app/profile/changepassword" >
       {authGuard(ChangePassword)}
       </Route>
       <Route exact path="/app/awb/track" >
       {authGuard(AWBTrack)}
       </Route>
       <Route exact path="/app/delivery/update" >
       {authGuard(DeliveryUpdate)}
       </Route>
       <Route exact path="/app/pod/upload" >
       {authGuard(PODUpload)}
       </Route>
       
       
      
     
    </Switch>
    
  </Router>

);
export default Routes;
// // import your route components too

// // function PrivateRoute({ children }) {
  
// //   var auth = localStorage.getItem("token");
// //   if(auth==null)
// //   {
// //     auth=false;
// //   }
  
// //   return auth ? children :  <Navigate to="/account/login"  />//navigate('/', { replace: true });
// // }
// // const authGuard = (Component) => () => 
// // {
// //   return localStorage.getItem("token") ? (
// //     <Component />
// //   ) : (
// //     <Navigate to="/account/login"  />
// //   );
// // };
// const Routesnav = (props) => (
// //class Routesnav extends React.Component{
  
// //render(){
//   //return(
//     <Router {...props}>
//       <Routes>
     
//       {/* <Route exact path="/account/login" element={<Login/>}/> */}
//       {/* <Routes>
//       <Route path="/account/login"  >
//       <Login />
//       </Route> */}
//       <Route exact path="/account/login" element={<Login/>}/>
//       <Route exact path="/" element={ <Home />} />
//       <Route path="/app/dashboard" render={<PrivateRoute> <Home /></PrivateRoute>} />
//       <Route path="/app/profile" render={<PrivateRoute><Profile /></PrivateRoute>} />
//       <Route path="/app/profile/changepassword" render={<PrivateRoute><ChangePassword /></PrivateRoute>} />
//       <Route path="/app/pickups" element={<Pickups />} />
//       <Route path="/app/pickups/request" render={<PrivateRoute><PickupRequest /></PrivateRoute>} />
//       <Route path="/app/pickups/details" element={ <PickupDetails /> } />

//       {/* <Route path="/app/pickups/details" element={<PickupDetails {...props} />}></Route> */}
     
//         <Route path="/app/pickups/assigned" render={<PrivateRoute><PickupAssigned /></PrivateRoute>} />
//         <Route path="/app/pickups/DriverDetails" render={<PrivateRoute><PickupDriverDetails /></PrivateRoute>} />
//         <Route path="/app/pickups/DriverDetail" render={<PrivateRoute><PickupDriverDetail /></PrivateRoute>} />
        
      
//       <Route path="/app/tickets" render={<PrivateRoute><Tickets /></PrivateRoute>} />
//       <Route path="/app/tickets/create" render={<PrivateRoute><TicketsCreate /></PrivateRoute>} />
//       <Route path="/app/tickets/details" render={<PrivateRoute><TicketsDetails /></PrivateRoute>} />

//     {/* <Route component={Home} ></Route>
//        */}
//       </Routes>
//     </Router>
  
  
  
// //)
//     //}
// );
// //}

//  export default Routesnav;