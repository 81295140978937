import React ,{ useState,useEffect}from 'react';
import {Link
} from "react-router-dom";
import { useForm } from "react-hook-form";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft,faCamera } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi,APIcustomerID } from '../../Utilities/Api'
import Footer from '../Shared/Footer';

const Update = () => 
{
  const { register, handleSubmit, formState: { errors }, watch ,setValue } = useForm();
  const [message, setMessage] = useState();
  const [isvaliddata, setData] = React.useState(false);
  const [toggle, setToggle] = useState(false)

  // const history = useHistory();
  
  const [items, setItems] = React.useState([]);
  
  
  
  
  useEffect(() => 
  {
    
        async function getCharacters() {
            const requestData={}
            const usaProfileDetails=callApi("POST",requestData,Constant.awbstatuslist);
            usaProfileDetails.then((responseData)=>{
                setItems(responseData.DataResponse.map(({ StatusName,StatusId }) => ({ label: StatusName, value: StatusId })));
    
                });
          }
          getCharacters();
      
}, []);

  const onSubmit = (data, e) => {
if(isvaliddata)
{
    setMessage({data: "Your Delivery update is in progress...",type: "alert alert-warning",});
        const requestData={PODID:APIcustomerID(),GRNum:data.awbno,PODNumber:''
        ,Attachment:''
        ,Remarks:data.remarks
        ,DeliveryDate:data.ddate
        ,DeliveryTime:Date.now
        ,RecieverName:data.receivername
        ,RecieverPhone:data.receivernumber
        ,AdminComment:''
        ,StatusId:data.ddlstatus
        ,StatusName:data.ddlstatus
        ,UserId:APIcustomerID()
        ,DMLFlag:'I'
        };//JSON.stringify(data)
        const usaData=callApi("POST",requestData,Constant.awbpodupload);
        usaData.then((responseData)=>{
          //console.log(responseData.DataResponse.error);
          setMessage({
            data: responseData.DataResponse.error || "Your Delivery update has been updated successfully...",
            type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
          });
          !responseData.DataResponse.error && e.target.reset();
    
        });
      }
      else
      {
        // setMessage({
        //   data: "Failed to update " + data.awbno + " Please try again",
        //   type: "alert alert-danger" ,
        // });
      }
  };
  const OnchangeGrno =(data, e) => {
  
   if(data.target.value.length>5)
   {
     var GRNOData=data.target.value;
     
    getCharacters(GRNOData);
   }
  };
  function getCharacters(GRNOData) {
    const requestData={GRNo:GRNOData}
    const usaProfileDetails=callApi("POST",requestData,Constant.Awbawbdeliveryvalidate);
    usaProfileDetails.then((responseData)=>{

      if(responseData.DataResponse=='Invalid')
      {
        setData(false);
        setMessage({
          data: GRNOData + " is a invalid consignment No! Please try again!",
          type: "alert alert-danger" ,
        });
      }
     else if(responseData.DataResponse=='Exist')
      {
        setData(false);
        setMessage({
          data: "Item already delivered for the consignment No " + GRNOData,
          type: "alert alert-danger" ,
        });
      }
      else if(responseData.DataResponse=='Valid')
      {
        setData(true);
        // setMessage({
        //   data: GRNOData + " Successfully Uploaded!",
        //   type: "alert alert-success" ,
        // });
      }
      else{
        setData(false);
      setMessage({
        data: "Failed to update " + GRNOData + " Please try again",
        type: "alert alert-danger" ,
      });
    }
            
        });
  }
        return(
            <div className="App">

            
<header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <Link to="/app/dashboard"  className="back">
        
        <FontAwesomeIcon icon={faArrowLeft} />
</Link>
                    <h1>
                    Delivery Update
                    </h1>

                </div>
            </div>
        </div>
    </header>
    <section>
        <div className="container">
            <div className="row justify-content-center mt-3">
            <div className="col-md-8  mt-3">
            {message && (
          <div
            role="alert"
            className={message.type}>
            {message.data}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
<div className="upload_inv">
<div className="form-group Scanners">
{toggle && (
       <BarcodeScannerComponent 
       // width={400}
       // height={400}
       // stopStream={this.state.isstopStream}
       onUpdate={(err, result) => {
         if (result) {
           //setData(result.text);
           setValue('awbno', result.text);
           getCharacters(result.text);
           setToggle(!toggle);
         }

         else setData("");
       }}
     />
      )}
    {/* {this.isstopStream ? ( */}
                          
                        {/* ) : (
                          ""
                        )} */}
   
   <div className="input-group mb-3">
   <input type="text" 
    
   { ...register('awbno', { required:{value:true,message:"Enter AWB No"},
   //value:data
   // validate: {
   //   positive: v => parseInt(v) > 0,
   //   lessThanTen: v => parseInt(v) < 10,
   //   checkUrl: async () => await fetch(),
   // }
  })
     } 
     
     onChange={(e) => {
      
       OnchangeGrno(e);
  }}
   required="required" placeholder="Enter AWB No" className="form-control form_control" />
  {errors.awbno && (
                <span className=" mandatory">
                  {errors.awbno.message}
                </span>
              )}
  <div className="input-group-append">
    <button className="btn btn-outline-secondary btncam " type="button"
    onClick={() => setToggle(!toggle)}
    //  onClick={this.handleChangeMenu}
     ><FontAwesomeIcon icon={faCamera} /></button>
  </div>
</div>
    
    

    
        
    </div>
    {/* <div className="form-group">
    <input type="text" { ...register('awbno', { required:{value:true,message:"Enter AWB No"},
              validate: {
                positive: v => parseInt(v) > 0,
                lessThanTen: v => parseInt(v) < 10,
                checkUrl: async () => await fetch(),
              }
             })
                } 
                
                onChange={(e) => {
                 
                  OnchangeGrno(e);
             }}
                className="form-control form_control"  placeholder="Enter AWB No" />
        
        {errors.awbno && (
                <span className=" mandatory">
                  {errors.awbno.message}
                </span>
              )}
    </div> */}
    <div className="form-group">
    <select className="form-control form_control" { ...register('ddlstatus')
              }>
              {items.map(({ label, value }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ))}

              </select>
       
    </div>
    <div className="form-group ">
        <span>Select Delivery Date</span>
        <input type="date" { ...register('ddate', { required:{value:true}
              
             })
                } className="form-control form_control" required="required" placeholder="Enter Date" />
        
        {errors.ddate && (
                <span className=" mandatory">
                  {errors.ddate.message}
                </span>
              )}
       
    </div>
    <div className="form-group form_group">
    <input type="text" { ...register('receivername', { required:{value:true,message:"Enter Receiver Name"}
              
            })
               } className="form-control form_control" placeholder="Enter Receiver Name" />
       
       {errors.receivername && (
               <span className=" mandatory">
                 {errors.receivername.message}
               </span>
             )}
       
    </div>
    <div className="form-group">
    <input type="text" { ...register('receivernumber'
    // , { required:{value:true,message:"Enter Receiver Number"}
              
    //         }
            )
               } className="form-control form_control" placeholder="Enter Receiver Number" />
       
       {/* {errors.receivernumber && (
               <span className=" mandatory">
                 {errors.receivernumber.message}
               </span>
             )} */}
        
    </div>
    <div className="form-group">
    <input type="text" { ...register('remarks'
    // , { required:{value:true,message:"Enter Remarks"}}
    )
               } className="form-control form_control" placeholder="Enter Remarks" />
       
       {/* {errors.remarks && (
               <span className=" mandatory">
                 {errors.remarks.message}
               </span>
             )} */}
       
    </div>
    <button type="submit" className="inv_smt">
        Submit
    </button>
</div>
</form>
</div>
                
            </div>
        </div>
    </section>
<Footer />
            </div>

            
);
};

export default Update;