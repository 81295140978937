
export async function callApi(methodType,requestBody,url){ 
    if(methodType==="POST"){
        const requestOptions = {
            method:methodType,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestBody)
        };
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        return data;
       
    }else{
        const requestOptions = {
            method:methodType,
            headers: { 'Content-Type': 'application/json' },
        };
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        return data;
    }
   
}

export function APIcustomerID()
{ 
    return localStorage.getItem("token")

}
