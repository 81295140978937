import React,{ useEffect } from 'react';
import {Link
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi,APIcustomerID } from '../../Utilities/Api'
import Footer from '../Shared/Footer';
const Profile = () => {
    const { register, formState: { errors },setValue } = useForm();
    
    useEffect(() => {
        // if (!isAddMode) {
            // get user and set form fields
            const requestData={customerid:APIcustomerID()}
            const usaProfileDetails=callApi("POST",requestData,Constant.CustomerDetails);
            usaProfileDetails.then((responseData)=>{

            responseData.DataResponse.forEach(team => 
              {
                
                setValue('CustomerLoginID', team.CustomerLoginID)
                // setValue('CompanyName', team.CompanyName)
                setValue('FirstName', team.FirstName)
                setValue('LastName', team.LastName)
                
                setValue('EmailID', team.EmailID)
                setValue('MobileNo', team.MobileNo)
                setValue('Address', team.Address)
                
                
             
                setValue('usertype', team.RoleID)

                  
            });
        
                });
          
    }, []);
      
    
    
        return(
            <div className="App">
           <header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                
                
                <Link to="/app/dashboard"  className="back">
        
                <FontAwesomeIcon icon={faArrowLeft} />
     </Link>
                    
                    <h1>
                        My Profile
                    </h1>

                </div>
            </div>
        </div>
    </header>
          <section>
        <div className="container">
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <div className="upload_inv att_rqt">




                        {/* <div className="form-group">

                            <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Company Name<sup className="text-danger"></sup></label>
                            <input type="text" { ...register('CompanyName', 
                            { required:{value:true,message:"Enter Company Name"},
                            maxLength:{value:25,message:"Please enter max 25 characters"},
                            minLength:{value:3,message:"Please enter min 3 characters"} })
                            }
                             className="form-control" placeholder="Enter Company Name"  />
                       
                        </div> */}
                        <div className="form-group">
                            <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>First Name<sup className="text-danger"></sup></label>
                            <input type="text" { ...register('FirstName', 
                            { required:{value:true,message:"Enter First Name"},
                            maxLength:{value:25,message:"Please enter max 25 characters"},
                            minLength:{value:3,message:"Please enter min 3 characters"} })
                            }
                             className="form-control" placeholder="Enter First Name" disabled />
                            
                        </div>
                        <div className="form-group">
                            <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Last Name <sup className="text-danger"></sup></label>
                            <input type="text" { ...register('LastName', 
                            { required:{value:true,message:"Enter Last Name"},
                            maxLength:{value:25,message:"Please enter max 25 characters"},
                            minLength:{value:3,message:"Please enter min 3 characters"} })
                            }
                             className="form-control" placeholder="Enter First Name" disabled />
                            
                        </div>

                        <div className="form-group">
                            <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Email ID <sup className="text-danger"></sup></label>
                            <input type="text" { ...register('EmailID', 
                            { required:{value:true,message:"Enter Email ID"},
                            maxLength:{value:25,message:"Please enter max 25 characters"},
                            minLength:{value:3,message:"Please enter min 3 characters"} })
                            }
                             className="form-control" placeholder="Enter EmailID" disabled />
                            
                        </div>
                        <div className="form-group">
                            <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Mobile No <sup className="text-danger"></sup></label>
                            
                            <input type="text" { ...register('MobileNo', 
                            { required:{value:true,message:"Enter Mobile No"},
                            maxLength:{value:25,message:"Please enter max 25 characters"},
                            minLength:{value:3,message:"Please enter min 3 characters"} })
                            }
                             className="form-control" placeholder="Enter Mobile No" disabled />
                            
                        </div>
                        <div className="form-group">
                            <label for="" className="d-flex align-items-center"><i className="fas fa-flag"></i>Address <sup className="text-danger"></sup></label>
                            <input type="text" { ...register('Address', 
                            { required:{value:true,message:"Enter Address"},
                            maxLength:{value:25,message:"Please enter max 25 characters"},
                            minLength:{value:3,message:"Please enter min 3 characters"} })
                            }
                             className="form-control" placeholder="Enter Address" disabled />
                      
                        </div>
                       
                      


                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
      
            </div>

            
        )
    

}
export default Profile;