import React from 'react';
import {Link } from "react-router-dom";

import footerhome from '../../Content/Img/Home.svg';
import footergr from '../../Content/Img/awbtrack.svg';
import Footerprofile from '../../Content/Img/Profile.svg';
import dashboardpod from '../../Content/Img/pod.svg';
export default function Footer() {

    
        return(
<section className="botom_nav">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <ul>
                        <li className="active">
                        <Link to="/app/dashboard"  >
        
                        <img src={footerhome} alt="Home" />
                                <span>Home</span>
                        </Link>
                            
                        </li>
                        <li>
                        <Link to="/app/awb/track"  >
        
                        <img src={footergr} alt="Help" />
                                <span>AWB Track</span>
        </Link>
                           
                        </li>
                        <li>
                        <Link to="/app/pod/upload"  >
                            <img src={dashboardpod} alt='POD Upload' />
                                    
                                    <span>
                                    POD Upload
                                    </span>
                            </Link>
                        {/* <Link to="/app/tickets/create"  >
        
                        <img src={footerhelps} alt="Help" />
                                <span>Help</span>
        </Link> */}
                           
                        </li>
                        <li>
                        <Link to="/app/profile"  >
        
                        <img src={Footerprofile} alt="My Profile" />
                                <span>My Profile</span>
                        </Link>
                           
                        </li>
                        
                        
                        
                       
                    </ul>
                </div>
            </div>
        </div>
    </section>
    )
    
}
 