import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import './App.css';


import reportWebVitals from './reportWebVitals';
import * as registerServiceWorker from 'register-service-worker'


// import {
//   BrowserRouter,

// } from "react-router-dom";
 import Routes from "./Routes"
 

// ReactDOM.render(
//   <BrowserRouter>
//   <Routesimp />
//    </BrowserRouter>,
//   document.getElementById("root")
// );
 ReactDOM.render(<Routes  />, document.getElementById("root"));
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
registerServiceWorker.register();
reportWebVitals();

