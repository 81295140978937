import React , { useState } from 'react';
 import { useHistory  } from "react-router-dom";
import { useForm } from "react-hook-form";

import loginimg from '../../Content/Img/login.svg';
import Lscreen from '../../Content/Img/Lscreen.jpg';

import user from '../../Content/Img/user.svg';
import lock from '../../Content/Img/lock.svg';
import * as Constant from '../../Utilities/Constant';
import { callApi } from '../../Utilities/Api'

const Login = () => 
{
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [message, setMessage] = useState();
  // const navigate  = useNavigate();
  const history = useHistory();
  const onSubmit = (data, e) => 
  {
    setMessage({data: "Login is in progress...",type: "alert alert-primary",});
    
    const requestData={username:data.username,password:data.password}
    const usaData=callApi("POST",requestData,Constant.CustomerLogin);
    usaData.then((responseData)=>{
      console.log(responseData.DataResponse.error);
      setMessage({
        data: responseData.DataResponse.error || "Logged in successfully, redirecting to dahsboard...",
        type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
      });

      if(responseData.DataResponse.error===null)
      {
      


        localStorage.setItem("token", responseData.DataResponse.Data.token);
        setTimeout(() => {
          //navigate('/app/dashboard');
            history.push("/");
          }, 1000);
        // const requestData={customerid:responseData.DataResponse.Data.token}
        // const usaProfileDetails=callApi("POST",requestData,Constant.USDATACURRENTSUBS);
        // usaProfileDetails.then((responseData)=>{

        //   let checksubs=responseData.DataResponse[0].Plans
        //   if(checksubs===null)
        //   {
        //     setTimeout(() => {
         
        //       history.push("/");
        //     }, 1000);
        //   }
        //   else if(checksubs==="NA")
        //   {
        //     setTimeout(() => {
         
        //       history.push("/");
        //     }, 1000);
        //   }
        //   else{
        //     setTimeout(() => {
         
        //       history.push("/app/dashboard");
        //     }, 1000);
        //   }

          
        // });


       
      }
      !responseData.DataResponse.error && e.target.reset();
       //this.setState({isSubmitting: false});
      //this.setState({usadata:responseData.data})
    });
    
  }
  
        return(
            <div classNameName="App">
                <section className="login_sec d-flex">
        <div className="container m-auto">
            <div className="row justify-content-center">
                <div className="col-12 col-md-8 col-lg-8">
                    <div className="_login_box">
                        <div className="bg_box">
                            
                                <img src={Lscreen} className="img-fluid d-none d-lg-block" alt="NSSL" />
                                <img src={loginimg}  className="img-fluid d-lg-none" alt="NSSL" />
                            
                        </div>
                        <div className="logfield_box">
                        <img src={loginimg} className="img-fluid d-none d-lg-block"  alt="NSSL" />
                           
                            <p>
                                Welcome to <strong>New Shiv Shakti Logistics</strong> 
                            </p>
                            <h1>Login To Continue</h1>
                            {message && (
          <div
            role="alert" className={message.type}
          >
            <p className="mb-0">
            
            {message.data}  
            <span
              aria-hidden="true"
              className="alert-link float-right"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
            </p>
            
          </div>
        )}
                            <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
                                <div className="form-group">
                                <input type="email" { ...register('username', { required:{value:true,message:"Please enter your EmailID"}
              ,pattern: {
                // value: "/^d{10}$",
                message: 'Please enter valid EmailID.',
              },
            // maxLength:{value:10,message:"Please enter max 10 alphabate"},
            // minLength:{value:10,message:"Please enter min 150 alphabate"}
           })
              } className="form-control form_control" placeholder="Enter Email ID" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.username && (
                <span className=" mandatory">
                  {errors.username.message}
                </span>
              )}
                                    {/* <input type="text" placeholder="Mobile" className="form-control" autofocus /> */}
                                   <img src={user} alt="mobile login" />
                                </div>
                                <div className="form-group">
                                <input type="password" {...register('password', { required:{value:true, message: "Please enter password"},minLength:{value:5,message:"Please enter min 5 characters"},maxLength:{value:25,message:"Please enter max 10 characters"}  })} className="form-control form_control" placeholder="Enter password" />
              
              {errors.password && (
                <span className= "mandatory">
                  {errors.password.message}
                </span>
              )}
                                   <img src={lock} alt="lock" />
                                </div>
                                <div className=" text-center mt-4">
                                    <button type="submit"  className="blue_btn btn">
                                        Login
                                    </button>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
            
            </div>

        );
  
              
}
export default Login;