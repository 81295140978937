export const APiURl=
//"http://localhost:2344/";
"https://nodeapi.nssllogistics.com/";
export const CustomerLogin=APiURl+'api/customers/CustomerLogin';
export const CustomerDetails=APiURl+'api/customers/customerdetails';
export const CustomerChangePassword=APiURl+'api/customers/ChangePassword';
export const AwbTrancking=APiURl+'api/awb/awbtrack';
export const Awbawbpodvalidate=APiURl+'api/awb/awbpodvalidate';
export const Awbawbdeliveryvalidate=APiURl+'api/awb/awbdeliveryvalidate';
export const awbstatuslist=APiURl+'api/awb/awbstatuslist';
export const awbpodupload=APiURl+'api/awb/awbpodupload';
export const awbpoduploadfile=APiURl+'api/awb/awbpoduploadfile';




