import React ,{ useState}from 'react';
import {Link
} from "react-router-dom";
import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import * as Constant from '../../Utilities/Constant';
import { callApi,APIcustomerID } from '../../Utilities/Api'
import Footer from '../Shared/Footer';

const ChangePassword = () => 
{
  const { register, handleSubmit, formState: { errors }, watch } = useForm();
  const [message, setMessage] = useState();
  // const history = useHistory();
  const password = useState({});
  password.current = watch("Newpassword", "");
  //const state = {value:''}
  const onSubmit = (data, e) => {
    setMessage({data: "Your Password update is in progress...",type: "alert alert-warning",});
        const requestData={customerid:APIcustomerID(),OldPassword:data.oldpassword,NewPassword:data.Newpassword};//JSON.stringify(data)
        const usaData=callApi("POST",requestData,Constant.CustomerChangePassword);
        usaData.then((responseData)=>{
          //console.log(responseData.DataResponse.error);
          setMessage({
            data: responseData.DataResponse.error || "Your Password has been updated successfully...",
            type: responseData.DataResponse.error ? "alert alert-danger" : "alert alert-success",
          });
          !responseData.DataResponse.error && e.target.reset();
    
        });
  };
  // const CountryChange =(data, e) => {
  
  //  let page = data.selected;
  //  setValue({
  //    value:page
  //  })
   
  // };

        return(
            <div className="App">

            
<header className="inner">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-8">
                <Link to="/app/dashboard"  className="back">
        
        <FontAwesomeIcon icon={faArrowLeft} />
</Link>
                    <h1>
                    Change Password
                    </h1>

                </div>
            </div>
        </div>
    </header>
    <section>
        <div className="container">
            <div className="row justify-content-center mt-3">
                <div className="col-md-8">
                    <div className="upload_inv att_rqt">


                    {message && (
          <div
            role="alert"
            className={message.type}>
            {message.data}
            <span
              aria-hidden="true"
              className="ml-auto cursor-pointer"
              onClick={() => setMessage(null)}
            >
              &times;
            </span>
          </div>
        )}
 <form onSubmit={handleSubmit(onSubmit)}  autoComplete="off">
            <div className="row">
          <div className="form-group form_group col-sm-12 col-12">
              <label htmlFor="FirstName">Old Password</label>
              <span className="mandatory">*</span>
              <input type="password" { ...register('oldpassword', { required:{value:true,message:"Please enter your Old Password"}
              
            ,maxLength:{value:25,message:"Please enter max 25 characters"},
            minLength:{value:5,message:"Please enter min 5 characters"} })
              } className="form-control form_control" placeholder="Enter Old password" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.oldpassword && (
                <span className=" mandatory">
                  {errors.oldpassword.message}
                </span>
              )}
            </div>
            <div className="form-group col-12">
              <label htmlFor="Newpassword">New Password</label>
              <span className="mandatory">*</span>
              <input type="password" { ...register('Newpassword', { required:{value:true,message:"Please enter New Password"}
              
              ,maxLength:{value:10,message:"Please enter max 10 characters"},
              minLength:{value:5,message:"Please enter min 5 characters"} })
              } className="form-control form_control" placeholder="Enter New Password" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.Newpassword && (
                <span className=" mandatory">
                  {errors.Newpassword.message}
                </span>
              )}
            </div>
            <div className="form-group  col-12">
              <label htmlFor="Companyname">Confirm Password</label>
              <span className="mandatory">*</span>
              <input type="password" { ...register('ConfirmPassword', {
          validate: value =>
            value === password.current || "The passwords do not match"
        })
              } className="form-control form_control" placeholder="Enter Confirm Password" />
              
              {/**
               * we provide validation configuration for email field above
               * error message are displayed with code below
               */}
              {errors.ConfirmPassword && (
                <span className=" mandatory">
                  {errors.ConfirmPassword.message}
                </span>
              )}
            </div>
            
            <div className="form-group col-12">
           
              <button type="submit" className="inv_smt">
                Change Password
              </button>

            
            </div>
            </div>
          </form>
                        



                    </div>
                </div>
            </div>
        </div>
    </section>
<Footer />
            </div>

            
);
};

export default ChangePassword;